import { CurveFitType, UltimateStrengthUnit } from "../models/curvefit"
import Sensor from "../models/sensor"
import SampleUnit from "../models/units"
import differenceChartData, { DifferenceEntry } from "../ui/charts/temperatureDifferenceCalculator"
import { maximumSampleDate } from "./samples"
import { minimumSampleDate } from "./samples"

export const convertedTemperatureValue = (temperature: number, toUnit: SampleUnit, fromUnit: SampleUnit = "c"): number => {
  if (toUnit === fromUnit) {
    return temperature
  } else if (toUnit === "f" && fromUnit === "c") {
    return (temperature * (9/5) + 32)
  } else {
    return (temperature - 32) * (5/9)
  }
}

export const convertedDifferenceValue = (difference: number, toUnit: SampleUnit, fromUnit: SampleUnit = "c"): number => {
  if (toUnit === fromUnit) {
    return difference
  } else if (toUnit === "f" && fromUnit === "c") {
    return (difference * (9/5))
  } else {
    return (difference * (5/9))
  }
}

export const convertInitialMaturityValue = (initialMaturity: number, toUnit: SampleUnit, fromUnit: SampleUnit = "c") => {
  if (toUnit === fromUnit) {
    return initialMaturity
  } else if (toUnit === "f" && fromUnit === "c") {
    return initialMaturity * (9/5)
  } else {
    return initialMaturity * (5/9)
  }
}

export const convertKValue = (kValue: number, toUnit: SampleUnit, fromUnit: SampleUnit = "c") => {
  if (toUnit === fromUnit) {
    return kValue
  } else if (toUnit === "f" && fromUnit === "c") {
    return kValue * (5/9)
  } else {
    return kValue * (9/5)
  }
}

const megapascalToPSICoefficient = 145.03773773

export const convertUltimateStrengthValue = (ultimateStrength: number, toUnit: UltimateStrengthUnit, fromUnit: UltimateStrengthUnit = "mpa") => {
  if (toUnit === fromUnit) {
    return ultimateStrength
  } else if (toUnit === "psi" && fromUnit === "mpa") {
    return ultimateStrength * megapascalToPSICoefficient
  } else {
    return ultimateStrength / megapascalToPSICoefficient
  }
}

export const convertCurveFitIntercept = (intercept: number, toUnit: SampleUnit, toStrengthUnit: UltimateStrengthUnit, curveFitType: CurveFitType, slope: number, fromUnit: SampleUnit = "c", fromStrengthUnit: UltimateStrengthUnit = "mpa") => {
  if (curveFitType === "disabled" || curveFitType === "hyperbolic") {
    return null
  }
  if (toUnit === fromUnit) {
    if (toStrengthUnit === fromStrengthUnit) {
      return intercept
    } else if (toStrengthUnit === "psi" && fromStrengthUnit === "mpa") {
      return intercept * megapascalToPSICoefficient
    } else {
      return intercept / megapascalToPSICoefficient
    }
  } else if (toUnit === "f" && fromUnit === "c") {
    if (toStrengthUnit === fromStrengthUnit) {
      if (curveFitType === "log") {
        return intercept - Math.log10(9.0 / 5.0) * slope
      } else {
        return intercept - Math.log(9.0 / 5.0) * slope
      }
    } else if (toStrengthUnit === "psi" && fromStrengthUnit === "mpa") {
      if (curveFitType === "log") {
        return (intercept - (Math.log10(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      } else {
        return (intercept - (Math.log(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      }
    } else {
      if (curveFitType === "log") {
        return (intercept - (Math.log10(9.0 / 5.0) * slope)) / megapascalToPSICoefficient
      } else {
        return (intercept - (Math.log(9.0 / 5.0) * slope)) / megapascalToPSICoefficient
      }
    }
  } else {
    if (toStrengthUnit === fromStrengthUnit) {
      if (curveFitType === "log") {
        return intercept + Math.log10(9.0 / 5.0) * slope
      } else {
        return intercept + Math.log(9.0 / 5.0) * slope
      }
    } else if (toStrengthUnit === "psi" && fromStrengthUnit === "mpa") {
      if (curveFitType === "log") {
        return (intercept + (Math.log10(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      } else {
        return (intercept + (Math.log(9.0 / 5.0) * slope)) * megapascalToPSICoefficient
      }
    } else {
      if (curveFitType === "log") {
        return (intercept + (Math.log10(9.0 / 5.0) * slope)) / megapascalToPSICoefficient
      } else {
        return (intercept + (Math.log(9.0 / 5.0) * slope)) / megapascalToPSICoefficient
      }
    }
  }
}

export const convertSlope = (slope: number, toStrengthUnit: UltimateStrengthUnit, fromStrengthUnit: UltimateStrengthUnit = "mpa") => {
  if (toStrengthUnit === fromStrengthUnit) {
    return slope
  } else if (toStrengthUnit === "psi" && fromStrengthUnit === "mpa") {
    return slope * megapascalToPSICoefficient
  } else {
    return slope / megapascalToPSICoefficient
  }
}

export type MaxDifferenceCalculation = {
  maxDifference: number,
  time: Date
  minSensor: Sensor,
  maxSensor: Sensor
}

export const calculateMaxDifference = (sensors: Sensor[]) : MaxDifferenceCalculation | null => {
  const minimumDate = minimumSampleDate(sensors)
  const maxDate = maximumSampleDate(sensors)

  if (!minimumDate || !maxDate) {
    return null
  }
  const differencePoints: Map<number, DifferenceEntry> = differenceChartData(sensors, minimumDate, maxDate)
  
  let maxDifference: number | null = null
  let maxDifferenceDate: Date | null = null

  differencePoints.forEach((point: DifferenceEntry, key: number) => {
    const difference = point.max - point.min
    if (maxDifference === null || difference > maxDifference) {
      maxDifference = difference
      maxDifferenceDate = new Date(key)
    }
  })

  if (maxDifferenceDate === null || maxDifference === null) {
    return null
  }

  let minSensor: Sensor | null = null
  let maxSensor: Sensor | null = null

  let minValue: number | null = null
  let maxValue: number | null = null

  // get the sensor's sample at the max difference date
  sensors.forEach((sensor: Sensor) => {
    const sample = sensor.samples?.find((sample) => {
      return sample.time.valueOf() === maxDifferenceDate?.valueOf()
    })
    if (sample) { 
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (minSensor === null || sample.temperature < minValue!) {
        minSensor = sensor
        minValue = sample.temperature
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (maxSensor === null || sample.temperature > maxValue!) {
        maxSensor = sensor
        maxValue = sample.temperature
      }
    }
  })

  if (!minSensor || !maxSensor || !minSensor || !maxSensor || !maxDifference) {
    return null
  }

  return {
    maxDifference: maxDifference,
    time: maxDifferenceDate,
    minSensor: minSensor,
    maxSensor: maxSensor
  }
}
