import axios from "axios"
import Project from "../models/project"
import Sample from "../models/sample"
import Participant from "../models/participant"
import { Invitation, Permission } from "../models/invitation"
import { Alert } from "../models/alert"
import { AlertRecipient } from "../models/alertrecipient"
import { AlertTrigger } from "../models/alerttrigger"
import moment from "moment"
import TemperatureLimit from "../models/temperaturelimit"
import MaturityMethod, { MaturityParametersEntry } from "../models/maturitymethod"
import CurveFit, { CurveFitParametersEntry } from "../models/curvefit"
import SampleUnit from "../models/units"
import Placement from "../models/placement"
import { Goal } from "../models/goal"
import Sensor from "../models/sensor"

export const fetchProjects = async (token: string) : Promise<Project[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data as Project[]
}

type ServerSample = {
  id: number
  temperature: number
  time: Date
  updatedAt: Date
}

export const fetchSensorData = async (token: string, projectId: number, placementId: number, sensorId: number) : Promise<Sample[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}/sensors/${sensorId}/samples`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })

  return results.data.map((serverSample: ServerSample) : Sample => {
    return {
      id: serverSample.id,
      temperature: serverSample.temperature,
      time: moment(serverSample.time),
      updatedAt: moment(serverSample.updatedAt)
    }
  }).sort((a: Sample, b: Sample) => { a.time.valueOf() - b.time.valueOf() })
}

export const fetchInvitations = async (token: string) : Promise<Invitation[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_INVITATIONS_API}/api/v2/invitations`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const acceptInvitation = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations/accept`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const declineInvitation = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations/decline`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const fetchAlerts = async (token: string, projectId: number, placementId: number) : Promise<Alert[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alerts`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchAlertRecipients = async (token: string, projectId: number, placementId: number) : Promise<AlertRecipient[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/recipients`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchAlertTriggers = async (token: string, projectId: number, placementId: number) : Promise<AlertTrigger[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alert-triggers`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchInvitationsForProject = async (token: string, projectId: number) : Promise<Invitation[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const fetchProjectParticipantList = async (token: string, projectId: number) : Promise<Participant[]> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createNewProject = async (token: string, name: string) : Promise<Project> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects`, {
    name: name,
    description: "",
    isArchived: false
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateProject = async (token: string, projectId: number, name: string) : Promise<Project> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}`, {
    name: name,
    description: "",
    isArchived: false
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const archiveProject = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/archive`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const unarchiveProject = async (token: string, projectId: number) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/unarchive`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const createInvitation = async (token: string, projectId: number, email: string, permission: Permission) : Promise<boolean> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations`, {
    projectId: projectId,
    email: email,
    permission: permission
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const deleteInvitation = async (token: string, projectId: number, email: string) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/invitations?email=${encodeURIComponent(email)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const updatePermission = async (token: string, projectId: number, userId: string, permission: Permission) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions`, {
    projectId: projectId,
    userId: userId,
    permission: permission
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const deletePermission = async (token: string, projectId: number, userId: string) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PROJECT_API}/api/v2/projects/${projectId}/permissions?userId=${encodeURIComponent(userId)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const getPlacement = async (token: string, projectId: number, placementId: number) : Promise<Placement> => {
  const results = await axios.get(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updatePlacement = async (token: string, projectId: number, placementId: number, name: string, timeZone: string, temperatureUnit: string, temperatureLimitId: number, maturityMethodId: number) : Promise<Placement> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}`, {
    id: placementId,
    projectId: projectId,
    name: name,
    description: null,
    timeZone: timeZone,
    temperatureUnit: temperatureUnit,
    temperatureLimitId: temperatureLimitId,
    maturityMethodId: maturityMethodId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createPlacement = async (token: string, projectId: number, name: string, timeZone: string, temperatureUnit: string, temperatureLimitId: number, maturityMethodId: number) : Promise<Placement> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements`, {
    projectId: projectId,
    name: name,
    description: null,
    timeZone: timeZone,
    temperatureUnit: temperatureUnit,
    temperatureLimitId: temperatureLimitId,
    maturityMethodId: maturityMethodId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateTemperatureLimit = async (token: string, temperatureLimitId: number, minTemperature: number | null, maxTemperature: number | null, difference: number | null) : Promise<TemperatureLimit> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_TEMPERATURE_LIMIT_API}/api/v1/temperature-limits/${temperatureLimitId}`, {
    id: temperatureLimitId,
    minimumTemperature: minTemperature,
    maximumTemperature: maxTemperature,
    difference: difference
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createTemperatureLimit = async (token: string, minTemperature: number | null, maxTemperature: number | null, difference: number | null) : Promise<TemperatureLimit> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_TEMPERATURE_LIMIT_API}/api/v1/temperature-limits`, {
    minimumTemperature: minTemperature,
    maximumTemperature: maxTemperature,
    difference: difference
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateMaturityMethod = async (token: string, maturityMethodId: number, type: MaturityMethod["type"], temperatureUnit: SampleUnit, parameters: MaturityParametersEntry, curveFitId: number) : Promise<MaturityMethod> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_MATURITY_METHOD_API}/api/v1/maturity-methods/${maturityMethodId}`, {
    id: maturityMethodId,
    type: type,
    temperatureUnit: temperatureUnit,
    parameters: type === "disabled" ? null : parameters,
    curveFitId: curveFitId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createMaturityMethod = async (token: string, type: MaturityMethod["type"], temperatureUnit: SampleUnit, parameters: MaturityParametersEntry, curveFitId: number) : Promise<MaturityMethod> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_MATURITY_METHOD_API}/api/v1/maturity-methods`, {
    type: type,
    temperatureUnit: temperatureUnit,
    parameters: type === "disabled" ? null : parameters,
    curveFitId: curveFitId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateCurveFit = async (token: string, curveFitId: number, type: CurveFit["type"], parameters: CurveFitParametersEntry) : Promise<CurveFit> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_CURVE_FIT_API}/api/v1/curve-fits/${curveFitId}`, {
    id: curveFitId,
    type: type,
    parameters: type === "disabled" ? null : parameters
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createCurveFit = async (token: string, type: CurveFit["type"], parameters: CurveFitParametersEntry) : Promise<CurveFit> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_CURVE_FIT_API}/api/v1/curve-fits`, {
    type: type,
    parameters: type === "disabled" ? null : parameters
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const deleteGoal = async (token: string, projectId: number, placementId: number, goalId: number) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_GOAL_API}/api/v2/projects/${projectId}/placements/${placementId}/goals/${goalId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const createGoal = async (token: string, projectId: number, placementId: number, name: string, value: number) : Promise<Goal> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_GOAL_API}/api/v2/projects/${projectId}/placements/${placementId}/goals`, {
    name: name,
    value: value,
    enabled: true,
    placementId: placementId,
    projectId: projectId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const updateGoal = async (token: string, projectId: number, placementId: number, goalId: number, name: string, value: number) : Promise<boolean> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_GOAL_API}/api/v2/projects/${projectId}/placements/${placementId}/goals/${goalId}`, {
    id: goalId,
    name: name,
    value: value,
    enabled: true,
    placementId: placementId,
    projectId: projectId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const createAlert = async (token: string, projectId: number, placementId: number, type: Alert["type"], value: number | null) : Promise<Alert> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alerts`, {
    projectId: projectId,
    placementId: placementId,
    type: type,
    value: value
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const createRecipient = async (token: string, projectId: number, placementId: number, name: string, contact: string, type: AlertRecipient["type"]) : Promise<AlertRecipient> => {
  const results = await axios.post(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/recipients`, {
    name: name,
    contact: contact,
    type: type === "email" ? "email" : "text",
    project_id: projectId,
    placement_id: placementId
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const deleteAlert = async (token: string, projectId: number, placementId: number, alertId: number) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/alerts/${alertId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const deleteRecipient = async (token: string, projectId: number, placementId: number, recipientId: number) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_ALERTS_API}/api/v2/projects/${projectId}/placements/${placementId}/recipients/${recipientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}

export const updateSensor = async (token: string, projectId: number, placementId: number, sensorId: number, name: string | null, location: string | null, depth: number | null, serial: string, placementDate: string, sampleInterval: number, enableNotifications: boolean, endDate: string, depthUnit: Sensor["depthUnit"]) : Promise<Sensor> => {
  const results = await axios.put(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}/sensors/${sensorId}`, {
    id: sensorId,
    projectId: placementId,
    name: name,
    location: location,
    depth: depth,
    serial: serial,
    placementDate: placementDate,
    sampleInterval: sampleInterval,
    enableNotifications: enableNotifications,
    endDate: endDate,
    depthUnit: depthUnit
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.data
}

export const deleteSensor = async (token: string, projectId: number, placementId: number, sensorId: number) : Promise<boolean> => {
  const results = await axios.delete(`${process.env.REACT_APP_COMMAND_CENTER_CLOUD_PLACEMENT_API}/api/v2/projects/${projectId}/placements/${placementId}/sensors/${sensorId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Command-App-Version": "4.0.9"
    }
  })
  return results.status === 200
}