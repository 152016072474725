import moment from "moment"
import { convertedTemperatureValue } from "../utilities/calculations"
import Placement from "./placement"
import Sample from "./sample"
import { maximumSampleDate, samplesAfterPlacementDate } from "../utilities/samples"
import { maturityForSensor } from "../utilities/maturity"

type Sensor = {
  id: number
  depth?: number
  location?: string
  name: string
  serial: string
  depthUnit: "in" | "ft" | "cm" | "m" | "mm"
  enableNotifications: boolean
  updatedAt: string
  samples?: Sample[],
  placementDate: string,
  sampleInterval: number,
  endDate: string
}

export const displayNameForSensor = (sensor: Sensor) : string => {
  if (sensor.name != null && sensor.name.length > 0) {
    return sensor.name
  }
  return sensor.serial
}

export const earliestPlacementDate = (sensors: Sensor[]) => {
  if (sensors.length === 0) {
    return null
  }
  
  const dates = sensors.map((sensor) => {
    return moment(sensor.placementDate)
  })
  return moment.min(dates)
}

export const excelDataForSensor = (sensor: Sensor, placement: Placement) => {
  return [
    [
      "Name",
      sensor.name
    ],
    [
      "Serial Number",
      sensor.serial
    ],
    [
      "Time of Last Reading",
      maximumSampleDate([sensor]) ? moment(maximumSampleDate([sensor])).format("YYYY/MM/DD HH:mm") : ""
    ],
    [
      "Location",
      sensor.location ?? ""
    ],
    [
      "Depth",
      (sensor.depth ?? "") + " " + sensor.depthUnit
    ]
  ].concat(generateSampleDataForSensor(sensor, placement))
}

const generateSampleDataForSensor = (sensor: Sensor, placement: Placement) => {
  const results = generateTemperatureOnlySampleDataForSensor(sensor, placement)
  if (placement.maturityMethod.type === "disabled") {
    return results
  } else {
    const maturity = maturityForSensor(sensor, placement)
    if (placement.maturityMethod.type === "arrhenius") {
      results[0].push("Equivalent Age (hr)")
    } else {
      results[0].push(`TTF (º${placement.maturityMethod.temperatureUnit.toUpperCase()}-hr)`)
    }
    const hasStrength = placement.maturityMethod.curveFit.type !== "disabled"
    if (hasStrength) {
      let unit = "MPa"
      if (placement.maturityMethod.curveFit.parameters?.strengthUnit === "psi") {
        unit = "psi"
      }
      results[0].push(`Strength (${unit})`)
    }
    maturity.forEach((maturityValue, index) => {
      results[index + 1].push(Math.round(maturityValue.maturityValue).toFixed(0))
      if (hasStrength) {
        if (placement.maturityMethod.curveFit.parameters?.strengthUnit === "psi") {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          results[index + 1].push(maturityValue.strengthValue!.toFixed(0))
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          results[index + 1].push(maturityValue.strengthValue!.toFixed(2))
        }
      }
    })
    return results
  }
}

const generateTemperatureOnlySampleDataForSensor = (sensor: Sensor, placement: Placement) => {
  const rows = [
    [
      `Timestamp (${placement.timeZone})`,
      "Elapsed Time (d.hh:mm)",
      `Temperature (º${placement.temperatureUnit.toUpperCase()})`
    ]
  ]
  const samples = samplesAfterPlacementDate(sensor)
  const placementDate = moment(sensor.placementDate).tz(placement.timeZone)
  samples.forEach((sample) => {
    const sampleTime = moment(sample.time).tz(placement.timeZone)
    // get the elapsed time between the sample time and placement date
    const elapsedTime = sampleTime.diff(placementDate, "seconds")
    const days = Math.floor(elapsedTime / 86400)
    const hours = Math.floor((elapsedTime - (days * 86400)) / 3600)
    const minutes = Math.floor((elapsedTime - (days * 86400) - (hours * 3600)) / 60)
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const paddedHours = hours < 10 ? `0${hours}` : hours
    let dayString = ""
    if (days > 0) {
      dayString = `${days}.`
    }
    rows.push([
      moment(sample.time).tz(placement.timeZone).format("YYYY/MM/DD HH:mm"),
      `${dayString}${paddedHours}:${paddedMinutes}`,
      convertedTemperatureValue(sample.temperature, placement.temperatureUnit).toFixed(1)
    ])
  })
  return rows
}

export default Sensor
