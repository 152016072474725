import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import NavigationBarView from "../reusable/NavigationBarView"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import Sensor, { excelDataForSensor } from "../../models/sensor"
import { fetchSensorData } from "../../network/network"
import SensorListView from "../sensors/SensorListView"
import ChartBottomBar from "../sensors/ChartBottomBar"
import ChartTabs from "../sensors/ChartTabs"
import { maximumSampleDate, minimumSampleDate } from "../../utilities/samples"
import CloseIcon from "@mui/icons-material/Close"
import TemperatureDifferentialReportPreviewView from "../reports/TemperatureDifferential/TemperatureDifferentialReportPreviewView"
import moment from "moment-timezone"
import xlsx from "xlsx"
import { logExport } from "../../utilities/analytics"
import PlacementSpecificationsView from "../placements/specifications/PlacementSpecificationsView"
import AlertContainerView from "../placements/specifications/alerts/AlertContainerView"
import Placement, { convertToPlacementEntry, isPlacementEntryEqual, PlacementEntry } from "../../models/placement"
import { loadProjcetsSilently } from "../../store/project/projectSlice"

const MobileSensorListScreen = () => {

  const [loading, setLoading] = useState(false)
  const [sensors, setSensors] = useState<Sensor[]>([])
  const [colorMap, setColorMap] = useState<Map<number, string>>(new Map())
  const [selectedSensorIds, setSelectedSensorIds] = useState<number[]>([])
  const [placementSpecsAnchorElement, setPlacementSpecAnchorElement] = useState<HTMLElement | null>(null)

  const popoverIsOpen = Boolean(placementSpecsAnchorElement)

  const [showingChart, setShowingChart] = useState(false)

  const { projectId, placementId } = useParams()
  const loadedProjects = useAppSelector(state => state.project.projects)
  if (!projectId || !placementId) {
    console.log("No project ID in URL.")
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))
  const selectedPlacement = selectedProject?.placements.find(p => p.id === parseInt(placementId))
  if (selectedProject === undefined || selectedPlacement === undefined) {
    console.log("Project or placement not found.")
    return (<></>)
  }

  const navigate = useNavigate()
  
  const accessToken = useAppSelector(state => state.user.token)

  const selectedSensors = sensors.filter(sensor => selectedSensorIds.includes(sensor.id))
  const startDate = minimumSampleDate(selectedSensors)
  const endDate = maximumSampleDate(selectedSensors)

  moment.tz.setDefault(selectedPlacement.timeZone)

  const [showingTemperatureDifferentialReport, setShowingTemperatureDifferentialReport] = useState(false)

  useEffect(() => {
    if (!accessToken) {
      return
    }

    const loadSensorData = async () => {
      let needsToLoad = false

      const fetchedSensors = selectedPlacement.sensors.map(async (sensor: Sensor) => {
        const existingSensor = sensors.find(s => s.id === sensor.id)
        if (existingSensor) {
          return {
            ...sensor,
            samples: existingSensor.samples
          }
        } else {
          needsToLoad = true

          return {
            ...sensor,
            samples: await fetchSensorData(accessToken, selectedPlacement.projectId, selectedPlacement.id, sensor.id)
          }
        }
      })
      
      if (needsToLoad) {
        setLoading(true)
      }

      setSensors(await Promise.all(fetchedSensors))
      
      if (needsToLoad) {
        setLoading(false)
      }
    }

    loadSensorData()
  }, [selectedPlacement])

  useEffect(() => {
    setSelectedSensorIds([])
  }, [selectedPlacement.id])

  useEffect(() => {
    const colors = [
      "#3876DB",
      "#FF2700",
      "#00C200",
      "#FFEB00",
      "#FF9A00",
      "#8723A1",
      "#66FDFF",
      "#FFC7DE",
      "#BDFA00",
      "#F23BEB",
      "#A16C21",
      "#051582",
      "#9B1300",
      "#E4BCFF",
      "#008081",
      "#FFD8AE",
      "#A6FCC1",
      "#7F8100",
      "#F7F5CD"
    ]

    const sortedBySerial = sensors?.sort((a: Sensor, b: Sensor) => {
      if (a.serial < b.serial) {
        return -1
      } else if (a.serial > b.serial) {
        return 1
      } else {
        return 0
      }
    })

    const map: Map<number, string> = new Map()

    sortedBySerial?.forEach((sensor, index) => {
      map.set(sensor.id, colors[index % (colors.length - 1)])
    })

    setColorMap(map)
  }, [sensors])

  const [specsDialogOpen, setSpecsDialogOpen] = useState(false)
  const [alertsDialogOpen, setAlertsDialogOpen] = useState(false)
  const [specsChanged, setSpecsChanged] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const dispatch = useAppDispatch()

  const openSpecsDialog = () => {
    setSpecsChanged(false)
    setPlacementSpecAnchorElement(null)
    setSpecsDialogOpen(true)
  }

  const openAlertsDialog = () => {
    setPlacementSpecAnchorElement(null)
    setAlertsDialogOpen(true)
  }

  const handleSpecsChange = (entry: PlacementEntry) => {
    setSpecsChanged(!isPlacementEntryEqual(entry, convertToPlacementEntry(selectedPlacement)))
  }

  const closeSpecsDialog = () => {
    if (!specsChanged) {
      setSpecsDialogOpen(false)
    } else {
      setWarningDialogOpen(true)
    }
  }

  const handlePlacementUpdate = (placement: Placement) => {
    const updatedPlacements = selectedProject?.placements.map(p => {
      if (p.id === placement.id) {
        return placement
      }
      return p
    })
    if (selectedProject) {
      const updatedProject = {...selectedProject, placements: updatedPlacements}
      const updatedProjects = loadedProjects.map(p => {
        if (p.id === selectedProject.id) {
          return updatedProject
        }
        return p
      })
      dispatch(loadProjcetsSilently(updatedProjects))
    }
  }

  return (
    <>
      <NavigationBarView 
        title={selectedPlacement.name}
        backButtonTitle=""
        onBack={() => {
          navigate("/projects/" + projectId)
        }}
        showPlacementSettingsButton={selectedProject.userInfo.permission != "readonly"}
        onPlacementSettingsClicked={(event: React.MouseEvent<HTMLElement>) => {
          setPlacementSpecAnchorElement(event.currentTarget)
        }}
      />

      {loading &&
        <Box display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" sx={{
          height: "100%",
          width: "100%"
        }}>
          <CircularProgress />
        </Box>
      }

      {!loading && 
      <Box sx={{
        height: "calc(100% - 105px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}>
        <SensorListView 
          sensors={sensors}
          placement={selectedPlacement}
          colorMap={colorMap} 
          timezone={selectedPlacement.timeZone}
          temperatureUnit={selectedPlacement.temperatureUnit} 
          selectedSensors={selectedSensorIds} 
          reportsDisabled={selectedProject.userInfo.permission === "readonly"}
          onSensorSelectionChange={(changedSensor: Sensor): void => {
            const index = selectedSensorIds.indexOf(changedSensor.id)
            if (index === -1) {
              const newSelectionList = selectedSensorIds.slice()
              newSelectionList.push(changedSensor.id)
              setSelectedSensorIds(newSelectionList)
            } else {
              const newSelectionList = selectedSensorIds.slice()
              newSelectionList.splice(index, 1)
              setSelectedSensorIds(newSelectionList)
            }
          }} 
          onTemperatureDifferentialReportSelected={(): void => {
            setShowingTemperatureDifferentialReport(true)
          }}          
          onExportToExcelSelected={() => {
            const workbook = xlsx.utils.book_new()
            sensors.forEach(sensor => {
              const sheet = xlsx.utils.json_to_sheet(excelDataForSensor(sensor, selectedPlacement), { skipHeader: true })
              xlsx.utils.book_append_sheet(workbook, sheet, sensor.name ?? sensor.serial)
            })
            const filename = selectedPlacement.name + "_" + moment().format("YYYYMMDD[T]HHmmss") + ".xlsx"
            xlsx.writeFile(workbook, filename)
            logExport("excel", selectedPlacement.sensors.length)
          }}
        />
      </Box>
      }
      <ChartBottomBar
        chartingEnabled={selectedSensorIds.length > 0}
        onChartPressed={() => {
          setShowingChart(true)
        }} />
      <Dialog
        fullScreen
        open={showingChart}
        onClose={() => {
          setShowingChart(false)
        }}
      >
        <Toolbar>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{
            width: "100%",
            height: "44px"
          }}>
            <Typography variant="h6">
              Charts
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setShowingChart(false)
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <ChartTabs
          project={selectedProject}
          placement={selectedPlacement}
          selectedSensors={selectedSensors}
          colorMap={colorMap}
          startDate={startDate ?? moment()}
          endDate={endDate ?? moment()}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={showingTemperatureDifferentialReport}
        onClose={() => {
          setShowingTemperatureDifferentialReport(false)
        }}
      >
        <TemperatureDifferentialReportPreviewView 
          project={selectedProject}
          placement={selectedPlacement}
          selectedSensors={selectedSensors}
          colorMap={colorMap}
          onClose={() => {
            setShowingTemperatureDifferentialReport(false)
          }}
        />
      </Dialog>
      <Menu
        anchorEl={placementSpecsAnchorElement}
        open={popoverIsOpen}
        onClose={() => { setPlacementSpecAnchorElement(null) }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={ openSpecsDialog }>Specifications</MenuItem>
        <Divider variant="middle" component="li" />
        <MenuItem onClick={ openAlertsDialog }>Alerts</MenuItem>
      </Menu>
      <Dialog 
        sx={{    
          "& .MuiDialog-container": {
            alignItems: "flex-start"
          },
          "& .MuiDialog-paper": {
            maxWidth: "400px"
          }
        }} 
        maxWidth="sm" 
        fullWidth={false} 
        scroll="paper" 
        open={alertsDialogOpen} 
        onClose={() => { setAlertsDialogOpen(false) }}
      >
        <AlertContainerView
          projectId={selectedProject.id}
          placementId={selectedPlacement.id}
          onBack={() => {
            setAlertsDialogOpen(false)
          }}
        />
      </Dialog>
      <Dialog
        open={specsDialogOpen}
        onClose={closeSpecsDialog}
        fullWidth={true}
        fullScreen={true}
      >
        <PlacementSpecificationsView 
          placement={selectedPlacement} 
          project={selectedProject}
          onPlacementUpdate={ (p) => {
            handlePlacementUpdate(p)
            setSpecsDialogOpen(false)
          }}
          onClose={closeSpecsDialog}
          onEntryChange={handleSpecsChange}
        />
      </Dialog>
      <Dialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            You have unsaved changes that will be lost. Are you sure you want to cancel?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            setSpecsDialogOpen(false)
            setWarningDialogOpen(false)
          }}>Yes</Button>
          <Button variant="contained" onClick={() => {
            setWarningDialogOpen(false)
          }} autoFocus>
            Go back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MobileSensorListScreen
