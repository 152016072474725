import React, { useState } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { Box, Dialog, IconButton, Typography } from "@mui/material"
import Sensor from "../../models/sensor"
import EditIcon from "@mui/icons-material/Edit"
import SensorDetailsView from "./SensorDetailsView"
import { convertedTemperatureValue } from "../../utilities/calculations"
import SampleUnit from "../../models/units"
import { samplesAfterPlacementDate } from "../../utilities/samples"
import Placement from "../../models/placement"
import { maturityForSensor } from "../../utilities/maturity"
import moment from "moment"
import { deleteSensor, updateSensor } from "../../network/network"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { loadProjcetsSilently } from "../../store/project/projectSlice"
import { logDeletedSensor } from "../../utilities/analytics"
import { useIsDesktop } from "../../utilities/environment"

interface Props {
  sensor: Sensor
  temperatureUnit: SampleUnit
  color: string
  selected: boolean
  timezone: string
  onSelectionChange: () => void
  placement: Placement
}

const SensorView = (props: Props) => {
  const [sensorDetailsDialogOpen, setSensorDetailsDialogOpen] = useState(false)
  const samples = samplesAfterPlacementDate(props.sensor)
  const lastSample = samples.length > 0 ? samples[samples.length - 1] : null
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  const accessToken = useAppSelector(state => state.user.token)
  const dispatch = useAppDispatch()
  const loadedProjects = useAppSelector(state => state.project.projects)

  const isDesktop = useIsDesktop()

  const updateProjectsWithPlacement = (placement: Placement) => {
    const updatedProjects = loadedProjects.map(p => {
      if (p.id === placement.projectId) {
        return {
          ...p,
          placements: p.placements.map(pl => {
            if (pl.id === placement.id) {
              return placement
            }
            return pl
          })
        }
      }
      return p
    })

    dispatch(loadProjcetsSilently(updatedProjects))
  }

  const update = async (sensor: Sensor) => {
    if (!accessToken) {
      return
    }

    setUpdateInProgress(true)
  
    const response = await updateSensor(
      accessToken,
      props.placement.projectId,
      props.placement.id,
      props.sensor.id,
      sensor.name,
      sensor.location ?? null,
      sensor.depth ?? null,
      props.sensor.serial,
      sensor.placementDate,
      props.sensor.sampleInterval,
      sensor.enableNotifications,
      sensor.endDate,
      sensor.depthUnit
    )

    if (!response) {
      alert("Failed to update sensor")
    } else {
      const updatedPlacement = {
        ...props.placement,
        sensors: props.placement.sensors.map(s => {
          if (s.id === props.sensor.id) {
            return sensor
          }
          return s
        })
      }

      updateProjectsWithPlacement(updatedPlacement)
      
      setSensorDetailsDialogOpen(false)
    }

    setUpdateInProgress(false)
  }

  const remove = async () => {
    if (!accessToken) {
      return
    }

    setDeleteInProgress(true)
    const response = await deleteSensor(accessToken, props.placement.projectId, props.placement.id, props.sensor.id)
    setDeleteInProgress(false)

    if (!response) {
      alert("Failed to delete sensor")
      return
    }

    logDeletedSensor(props.placement.projectId, props.placement.id, props.sensor.serial)

    const updatePlacement = {
      ...props.placement,
      sensors: props.placement.sensors.filter(s => s.id !== props.sensor.id)
    }

    updateProjectsWithPlacement(updatePlacement)
    
    setSensorDetailsDialogOpen(false)
  }

  const closeSensorDetails = () => {
    if (updateInProgress || deleteInProgress) {
      return
    }

    setSensorDetailsDialogOpen(false)
  }

  const getSensorDisplayValue = () => {
    const lastSample = samples.length > 0 ? samples[samples.length - 1] : null
    if (lastSample === null) {
      return null
    }
    if (props.placement.maturityMethod.type === "disabled") {
      return `${convertedTemperatureValue(lastSample.temperature, props.temperatureUnit).toFixed(1)}º${props.temperatureUnit.toUpperCase()}`
    } else if (props.placement.maturityMethod.curveFit.type === "disabled") {
      const maturity = maturityForSensor(props.sensor, props.placement)
      const lastMaturity = maturity[maturity.length - 1]
      if (props.placement.maturityMethod.type === "arrhenius") {
        return `${lastMaturity.maturityValue.toLocaleString(undefined, {maximumFractionDigits: 0})} hr`
      } else {
        return `${lastMaturity.maturityValue.toLocaleString(undefined, {maximumFractionDigits: 0})} ${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
      }
    } else {
      const maturity = maturityForSensor(props.sensor, props.placement)
      const lastMaturity = maturity[maturity.length - 1]
      if (lastMaturity.strengthValue === undefined) {
        return null
      }
      if (props.placement.maturityMethod.curveFit.parameters?.strengthUnit === "psi") {
        return `${lastMaturity.strengthValue.toLocaleString(undefined, {maximumFractionDigits: 0})} psi`
      } else {
        return `${lastMaturity.strengthValue.toLocaleString(undefined, {maximumFractionDigits: 2})} MPa`
      }
    }
  }

  return (
    <>
      <Box 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between" 
        sx={{
          width: "100%",
          cursor: "pointer"
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" onClick={props.onSelectionChange} sx={{ width: "100%", marginRight: "5px" }}>
          {props.selected &&
            <CheckCircleIcon htmlColor={props.color} />
          }
          {!props.selected &&
            <CircleOutlinedIcon htmlColor={props.color} />
          }
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{
            marginLeft: "12px",
            width: "100%"
          }}>
            <Typography variant="body1" fontWeight="bold">
              {props.sensor.name ? props.sensor.name : props.sensor.serial}
            </Typography>
            {props.sensor.samples !== undefined && props.sensor.samples.length > 0 &&
              <Typography>
                {moment(props.sensor.samples[props.sensor.samples.length - 1].time).format("YYYY/MM/DD, HH:mm A")}
              </Typography>
            }
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" sx={{ whiteSpace: "nowrap" }}>
            {lastSample &&
              <Typography variant="subtitle1">
                {getSensorDisplayValue() ?? ""}
              </Typography>
            }
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton onClick={() => setSensorDetailsDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={sensorDetailsDialogOpen} onClose={closeSensorDetails} fullWidth fullScreen={!isDesktop}>
        <SensorDetailsView 
          sensor={props.sensor} 
          timezone={props.timezone} 
          onClose={closeSensorDetails}
          updateInProgress={updateInProgress}
          deleteInProgress={deleteInProgress}
          onUpdate={update}
          onDelete={remove}
        />
      </Dialog>
    </>
  )
}

export default SensorView
