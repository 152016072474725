import React, { useState } from "react"
import Sensor from "../../models/sensor"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputAdornment, InputLabel, List, ListItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import moment from "moment"
import { LoadingButton } from "@mui/lab"
import { useIsDesktop } from "../../utilities/environment"
import ValueCell from "../reusable/ValueCell"

interface Props {
  sensor: Sensor,
  timezone: string
  onClose: () => void
  onUpdate: (sensor: Sensor) => void
  onDelete: () => void
  updateInProgress: boolean
  deleteInProgress: boolean
}

const useStyles = makeStyles({
  switch: {
    "& .Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#000099 !important",
      },
    },
  }
})

const SensorDetailsView = (props: Props) => {
  const classes = useStyles()
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { samples, ...details } = props.sensor
  const [sensorDetails, setSensorDetails] = useState<Sensor>(JSON.parse(JSON.stringify(details)))
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const isDesktop = useIsDesktop()

  const sanitizeDepth = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseFloat(e.target.value).toFixed(0)

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const handleSave = () => {
    props.onUpdate(sensorDetails)
  }

  const isDisabled = props.updateInProgress || props.deleteInProgress
  const isPlacementDateBeforeFirstSample = props.sensor.samples && props.sensor.samples.length > 0 && moment(sensorDetails.placementDate).isBefore(moment(props.sensor.samples?.[0].time))

  return (
    <Box display="flex" flexDirection="column" alignItems="left" sx={{
      padding: "24px"
    }}>
      <Box display="table">
        <Typography variant="h5" sx={{display: "table-cell", verticalAlign: "middle"}}>
          Sensor Details
        </Typography>
        <Box display="table" flexDirection="row" sx={{ float: "right" }}>
          <Typography variant="body2" display="table-cell" sx={{ padding: "10px" }}>
            Alerts Enabled
          </Typography>
          <Switch checked={sensorDetails.enableNotifications} onChange={
            (e) => {
              setSensorDetails({
                ...sensorDetails,
                enableNotifications: e.target.checked
              })
            }
          } className={classes.switch} title="Alerts Enabled" color="secondary" disabled={isDisabled}/>
        </Box>
      </Box>
      <List sx={{
        width: "100%"
      }}>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <TextField
            label="Sensor Name"
            value={sensorDetails.name}
            placeholder="Enter sensor name"
            onChange={(e) => {
              setSensorDetails({
                ...sensorDetails,
                name: e.target.value
              })
            }}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            disabled={isDisabled}
          />
        </ListItem>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <TextField
            label="Location"
            value={sensorDetails.location}
            placeholder="Enter location"
            onChange={(e) => {
              setSensorDetails({
                ...sensorDetails,
                location: e.target.value
              })
            }}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            disabled={isDisabled}
          />
        </ListItem>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <TextField
            label="Placement Depth"
            defaultValue={sensorDetails.depth}
            placeholder="Enter depth"
            onBlur={(e) => {
              sanitizeDepth(e)

              setSensorDetails({
                ...sensorDetails,
                depth: parseFloat(e.target.value)
              })
            }}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            sx={{
              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                pr: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <FormControl sx={{ minWidth: 80,  border: "none" }}>
                  <Select
                    native
                    value={sensorDetails.depthUnit}
                    onChange={(e) => {
                      setSensorDetails({
                        ...sensorDetails,
                        depthUnit: e.target.value as Sensor["depthUnit"]
                      })
                    }}
                    sx={{ 
                      boxShadow: "none", 
                      ".MuiOutlinedInput-notchedOutline": { 
                        borderRight: "none",
                        borderTop: "none",
                        borderBottom: "none",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      } 
                    }}
                  >
                    <option value="in">in</option>
                    <option value="ft">ft</option>
                    <option value="mm">mm</option>
                    <option value="cm">cm</option>
                    <option value="m">m</option>
                  </Select>
                </FormControl>
              </InputAdornment>,
            }}
            disabled={isDisabled}
          />
        </ListItem>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <Box sx={{ width: "100%" }}>
            <FormControl variant="standard" fullWidth error={isPlacementDateBeforeFirstSample}>
              <InputLabel htmlFor="my-input" variant="outlined" shrink>Placement Date & Time</InputLabel>
              <OutlinedInput label="Placement Date & Time" id="my-input" aria-describedby="my-helper-text" notched type="datetime-local"
                defaultValue={moment(sensorDetails.placementDate).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => {
                  setSensorDetails({
                    ...sensorDetails,
                    placementDate: moment(e.target.value).tz(props.timezone).toDate().toISOString()
                  })
                }}
                disabled={isDisabled}
              />
            </FormControl>
            {isPlacementDateBeforeFirstSample &&
              <Typography variant="body2" color="error" sx={{paddingLeft: "12px", paddingRight: "12px"}}>
                Warning: Placement date is before the first data point available. The calculations displayed will start with the first available data point, and that point will be treated as the placement date.
              </Typography>
            }
          </Box>
        </ListItem>
        {isDesktop &&
          <ListItem sx={{
            paddingLeft: "0px",
            paddingRight: "0px",
          }}>
            <Box display="flex" flexDirection="row" width="100%">
              <Box sx={{ width: "50%" }}>
                <Typography variant="body2">
                  Serial Number
                </Typography>
                <Typography variant="body1">
                  {sensorDetails.serial}
                </Typography>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography variant="body2">
                  Sample Interval (min)
                </Typography>
                <Typography variant="body1">
                  {sensorDetails.sampleInterval}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        }
        {!isDesktop &&
          <>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <ValueCell title="Serial Number" value={sensorDetails.serial} />
            </ListItem>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <ValueCell title="Sample Interval (min)" value={sensorDetails.sampleInterval.toString()} />
            </ListItem>
          </>
        }
      </List>
      {isDesktop &&
        <Box display={isDesktop ? "table" : "flex"} flexDirection="column" sx={{
          marginTop: "24px",
          width: "100%"
        }}>
          <LoadingButton variant="contained" color="error" sx={{
            marginBottom: "12px",
          }} disabled={isDisabled} loading={props.deleteInProgress} onClick={() => setDeleteDialogOpen(true)}>
            Delete Sensor
          </LoadingButton>
          <LoadingButton variant="contained" color="primary" sx={{ float: "right", marginBottom: "12px" }} onClick={handleSave} disabled={props.updateInProgress} loading={props.updateInProgress}>
            Save Changes
          </LoadingButton >
          <Button variant="outlined" color="primary" sx={{
            marginRight: isDesktop ? "12px" : "0px",
            float: "right"
          }} onClick={props.onClose} disabled={isDisabled}>
            Cancel
          </Button>
        </Box>
      }
      {!isDesktop &&
        <Box display={isDesktop ? "table" : "flex"} flexDirection="column" sx={{
          marginTop: "24px",
          width: "100%"
        }}>
          <LoadingButton variant="contained" color="primary" sx={{ float: "right", marginBottom: "12px" }} onClick={handleSave} disabled={props.updateInProgress} loading={props.updateInProgress}>
            Save Changes
          </LoadingButton >
          <Button variant="outlined" color="primary" sx={{
            marginRight: isDesktop ? "12px" : "0px",
            float: "right"
          }} onClick={props.onClose} disabled={isDisabled}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="error" sx={{
            marginTop: "24px",
          }} disabled={isDisabled} loading={props.deleteInProgress} onClick={() => setDeleteDialogOpen(true)}>
            Delete Sensor
          </LoadingButton>
        </Box>
      }
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>
          Delete Sensor
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this sensor? You cannot undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" autoFocus onClick={() => {
            setDeleteDialogOpen(false)
            props.onDelete()
          }}>
            Delete Sensor
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default SensorDetailsView
