import moment from "moment"
import Sample from "../models/sample"
import Sensor from "../models/sensor"

/// Returns all samples in the sensor that are after the placement date
export const samplesAfterPlacementDate = (sensor: Sensor): Sample[] => {
  const placementDate = new Date(sensor.placementDate)
  return sensor.samples?.filter((sample: Sample) => sample.time.valueOf() >= placementDate.valueOf()) ?? []
}

export const samplesAfterDate = (sensor: Sensor, date: moment.Moment): Sample[] => {
  return samplesAfterPlacementDate(sensor).filter((sample: Sample) => sample.time.valueOf() >= date.valueOf())
}

export const latestSample = (sensor: Sensor): Sample | null => {
  if (!sensor.samples || sensor.samples.length === 0) {
    return null
  }
  return sensor.samples[sensor.samples.length - 1]
}

/// Returns the minimum date of all samples in the sensors
/// If a sensor has no samples, it is ignored
/// If a sensor has samples before the placement date, the first sample after the placement date is used
export const minimumSampleDate = (sensors: Sensor[]): moment.Moment | null => {
  let minimumDate: moment.Moment | null = null
  sensors.forEach(sensor => {
    const samples = samplesAfterPlacementDate(sensor)
    if (samples.length === 0) {
      return null
    }
    const firstSample = samples[0]

    if (!minimumDate || firstSample.time.valueOf() < minimumDate.valueOf()) {
      minimumDate = firstSample.time
    }
  })
  return moment(minimumDate)
}

/// Returns the maximum date of all samples in the sensors
/// If a sensor has no samples, it is ignored
/// If the sensor doesn't have samples after the placement date, the sensor is ignored
export const maximumSampleDate = (sensors: Sensor[]): moment.Moment | null => {
  let maximumDate: moment.Moment | null = null
  sensors.forEach(sensor => {
    const samples = samplesAfterPlacementDate(sensor)
    if (samples.length === 0) {
      return null
    }
    const lastSample = samples[samples.length - 1]

    if (!maximumDate || lastSample.time.valueOf() > maximumDate.valueOf()) {
      maximumDate = lastSample.time
    }
  })
  return moment(maximumDate)
}

export const shortestSampleInterval = (sensors: Sensor[]): number | null => {
  let shortestInterval: number | null = null
  sensors.forEach(sensor => {
    if (!shortestInterval || sensor.sampleInterval < shortestInterval) {
      shortestInterval = sensor.sampleInterval
    }
  })
  return shortestInterval
}

export const lowestSampleForSensor = (sensor: Sensor): Sample | null => {
  const samples = samplesAfterPlacementDate(sensor)
  if (!samples || samples.length === 0) {
    return null
  }
  let lowestSample: Sample = samples[0]
  samples.forEach(sample => {
    if (sample.temperature < lowestSample.temperature) {
      lowestSample = sample
    }
  })
  return lowestSample
}

export const highestSampleForSensor = (sensor: Sensor): Sample | null => {
  const samples = samplesAfterPlacementDate(sensor)
  if (!samples || samples.length === 0) {
    return null
  }
  let highestSample: Sample = samples[0]
  samples.forEach(sample => {
    if (sample.temperature > highestSample.temperature) {
      highestSample = sample
    }
  })
  return highestSample
}

export const lowestSample = (sensors: Sensor[]): Sample | null => {
  let lowestSample: Sample | null = null
  sensors.forEach(sensor => {
    const sample = lowestSampleForSensor(sensor)
    if (!lowestSample || (sample && sample.temperature < lowestSample.temperature)) {
      lowestSample = sample
    }
  })
  return lowestSample
}

export const highestSample = (sensors: Sensor[]): Sample | null => {
  let highestSample: Sample | null = null
  sensors.forEach(sensor => {
    const sample = highestSampleForSensor(sensor)
    if (!highestSample || (sample && sample.temperature > highestSample.temperature)) {
      highestSample = sample
    }
  })
  return highestSample
}
