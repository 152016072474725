import { FormControl, InputLabel, List, ListItem, NativeSelect, TextField, Typography } from "@mui/material"
import React from "react"
import MaturityMethod, { MaturityMethodEntry } from "../../../models/maturitymethod"
import { convertedTemperatureValue } from "../../../utilities/calculations"
import SampleUnit from "../../../models/units"
import { floatOrNull } from "./TemperatureLimitsView"

interface Props {
  entry: MaturityMethodEntry
  onEntryChange: (entry: MaturityMethodEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const MaturityMethodView = (props: Props) => {
  const sanitizeTemperature = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!props.entry.temperatureUnit) {
      e.target.value = ""
      return
    }

    e.target.value = convertedTemperatureValue(parseFloat(e.target.value), props.entry.temperatureUnit, props.entry.temperatureUnit).toFixed(1) ?? null

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeActivationEnergy = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseInt(e.target.value).toString()

    if (isNaN(parseInt(e.target.value))) {
      e.target.value = ""
    }
  }

  return (
    <>
      <Typography variant="caption">
        Maturity Method
      </Typography>
      <Typography sx={{
        fontSize: "10px",
        color: "gray"
      }}>
        Values below can be found on the maturity report
      </Typography>
      <List sx={{
        marginTop: "12px"
      }}>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <FormControl fullWidth>
            <InputLabel sx={{
              transform: "translate(0, 4px) scale(0.75)"
            }} variant="standard" htmlFor="uncontrolled-native-mm">
              Maturity Method
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: "maturityMethod",
                id: "uncontrolled-native-mm",
              }}
              sx={{
                padding: "6px 0px",
                marginTop: "6px",
                marginBottom: "6px"
              }}
              value={props.entry.type}
              onChange={(e) => {
                props.onEntryChange({
                  ...props.entry,
                  type: e.target.value as MaturityMethod["type"],
                  temperatureUnit: props.entry.temperatureUnit,
                  parameters: {
                    activationEnergy: null,
                    referenceTemperature: null,
                    datumTemperature: null
                  },
                  curveFit: {
                    ...props.entry.curveFit,
                    type: "disabled",
                    parameters: {
                      slope: null,
                      intercept: null,
                      ultimateStrength: null,
                      kValue: null,
                      strengthUnit: props.entry.curveFit.parameters.strengthUnit,
                      initialEquivalentAge: null,
                      initialMaturity: null,
                    }
                  }
                })
              }}
              fullWidth
              disabled={props.disabled}
            >
              <option value="disabled">Disabled</option>
              <option value="nurse saul">Nurse-Saul</option>
              <option value="arrhenius">Arrhenius</option>
            </NativeSelect>
          </FormControl>
        </ListItem>
        {props.entry.type === "nurse saul" &&
          <>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <FormControl fullWidth error={props.highlightRequiredFields && props.entry.temperatureUnit === ""}>
                <InputLabel sx={{
                  transform: "translate(0, 4px) scale(0.75)"
                }} variant="standard" htmlFor="uncontrolled-native-mmu">
                  Unit
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    name: "maturityMethodUnit",
                    id: "uncontrolled-native-mmu",
                  }}
                  sx={{
                    padding: "6px 0px",
                    marginTop: "6px",
                    marginBottom: "6px"
                  }}
                  value={props.entry.temperatureUnit}
                  onChange={(e) => {
                    props.onEntryChange({
                      ...props.entry,
                      temperatureUnit: e.target.value as SampleUnit
                    })
                  }}
                  fullWidth
                  disabled={props.disabled}
                >
                  {props.entry.temperatureUnit === "" &&
                    <option value="" disabled>Select Unit</option>
                  }
                  <option value="c">ºC-hr</option>
                  <option value="f">ºF-hr</option>
                </NativeSelect>
              </FormControl>
            </ListItem>
            { props.entry.temperatureUnit &&
              <ListItem sx={{
                paddingLeft: "0px",
                paddingRight: "0px"
              }}>
                <TextField
                  key={`datumTemperature-${props.entry.temperatureUnit}`}
                  label={`Datum Temp (º${props.entry.temperatureUnit.toUpperCase()})`}
                  placeholder="Required"
                  defaultValue={props.entry.parameters.datumTemperature?.toFixed(1)}
                  onBlur={(e) => {
                    sanitizeTemperature(e)

                    props.onEntryChange({
                      ...props.entry,
                      parameters: {
                        datumTemperature: floatOrNull(e.target.value),
                        referenceTemperature: null,
                        activationEnergy: null
                      }
                    })
                  }}
                  fullWidth
                  disabled={props.disabled}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={props.highlightRequiredFields && props.entry.parameters.datumTemperature === null}
                />
              </ListItem>
            }
          </>
        }
        {props.entry.type === "arrhenius" &&
          <>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <FormControl fullWidth error={props.highlightRequiredFields && props.entry.temperatureUnit === ""}>
                <InputLabel sx={{
                  transform: "translate(0, 4px) scale(0.75)"
                }} variant="standard" htmlFor="uncontrolled-native-mmu">
                  Reference Temp Unit
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    name: "maturityMethodUnit",
                    id: "uncontrolled-native-mmu",
                  }}
                  sx={{
                    padding: "6px 0px",
                    marginTop: "6px",
                    marginBottom: "6px"
                  }}
                  value={props.entry.temperatureUnit}
                  onChange={(e) => {
                    props.onEntryChange({
                      ...props.entry,
                      temperatureUnit: e.target.value as SampleUnit
                    })
                  }}
                  fullWidth
                  disabled={props.disabled}
                >
                  {props.entry.temperatureUnit === "" &&
                    <option value="" disabled>Select Unit</option>
                  }
                  <option value="c">ºC</option>
                  <option value="f">ºF</option>
                </NativeSelect>
              </FormControl>
            </ListItem>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <TextField
                label="Activation Energy (J/mol)"
                defaultValue={props.entry.parameters.activationEnergy}
                placeholder="Required"
                onBlur={(e) => {
                  sanitizeActivationEnergy(e)

                  props.onEntryChange({
                    ...props.entry,
                    parameters: {
                      referenceTemperature: props.entry.parameters.referenceTemperature,
                      activationEnergy: floatOrNull(e.target.value),
                      datumTemperature: null
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
                error={props.highlightRequiredFields && props.entry.parameters.activationEnergy === null}
              />
            </ListItem>
            { props.entry.temperatureUnit &&
              <ListItem sx={{
                paddingLeft: "0px",
                paddingRight: "0px"
              }}>
                <TextField
                  key={`referenceTemperature-${props.entry.temperatureUnit}`}
                  label={`Reference Temperature (º${props.entry.temperatureUnit.toUpperCase()})`}
                  placeholder="Required"
                  defaultValue={props.entry.parameters.referenceTemperature?.toFixed(1)}
                  onBlur={(e) => {
                    sanitizeTemperature(e)

                    props.onEntryChange({
                      ...props.entry,
                      parameters: {
                        referenceTemperature: floatOrNull(e.target.value), 
                        activationEnergy: props.entry.parameters.activationEnergy,
                        datumTemperature: null
                      }
                    })
                  }}
                  fullWidth
                  disabled={props.disabled}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={props.highlightRequiredFields && props.entry.parameters.referenceTemperature === null}
                />
              </ListItem>
            }
          </>
        }
      </List>
    </>
  )
}

export default MaturityMethodView
