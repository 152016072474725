import { Box, Button, Dialog, List, ListItem, ListItemButton, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import Placement from "../../models/placement"
import PlacementView from "./PlacementView"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { createCurveFit, createGoal, createMaturityMethod, createPlacement, createTemperatureLimit, fetchProjects } from "../../network/network"
import { loadProjcetsSilently } from "../../store/project/projectSlice"
import SampleUnit from "../../models/units"
import { TemperatureLimitEntry } from "../../models/temperaturelimit"
import MaturityMethod, { MaturityParametersEntry } from "../../models/maturitymethod"
import { CurveFitEntry, CurveFitParametersEntry } from "../../models/curvefit"
import NewPlacementView from "./NewPlacementView"
import { useIsDesktop } from "../../utilities/environment"
import { logAddedPlacement } from "../../utilities/analytics"
import { uniqueString } from "../../utilities/stringcasing"
import { GoalEntry } from "../../models/goal"

interface Props {
  onPlacementSelect: (placement: Placement) => void
}

const PlacementListView = (props: Props) => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const { projectId, placementId } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const [sortedPlacements, setSortedPlacements] = useState<Placement[]>([])
  const [newPlacementDialogOpen, setNewPlacementDialogOpen] = useState(false)
  const [isCreatingNewPlacement, setIsCreatingNewPlacement] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const accessToken = useAppSelector(state => state.user.token)
  const dispatch = useAppDispatch()
  const isDesktop = useIsDesktop()
  
  if (!projectId) {
    console.log("No project ID in URL.")
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))

  if (!selectedProject) {
    console.log("No project found.")
    return (<></>)
  }

  const placements = selectedProject?.placements ?? []
  let selectedPlacement: Placement | undefined = undefined
  if (placementId) {
    selectedPlacement = placements.find(p => p.id === parseInt(placementId))
  }

  const sortPlacements = () => {
    let placements = selectedProject?.placements.slice() ?? []
    if (selectedTab === 0) {
      placements = placements.sort((a: Placement, b: Placement) => {
        const aName = a.name?.toLowerCase()
        const bName = b.name?.toLowerCase()
        if (aName < bName) {
          return -1
        } else if (aName > bName) {
          return 1
        } else {
          return 0
        }
      })
    } else {
      placements = placements.sort((a: Placement, b: Placement) => {
        const aDate = Date.parse(a.updatedAt)
        const bDate = Date.parse(b.updatedAt)
        if (aDate > bDate) {
          return -1
        } else if (aDate < bDate) {
          return 1
        } else {
          return 0
        }
      })
    }
    setSortedPlacements(placements)
  }

  const handlePlacementUpdate = (placement: Placement) => {
    const updatedPlacements = selectedProject?.placements.map(p => {
      if (p.id === placement.id) {
        return placement
      }
      return p
    })
    if (selectedProject) {
      const updatedProject = {...selectedProject, placements: updatedPlacements}
      const updatedProjects = loadedProjects.map(p => {
        if (p.id === selectedProject.id) {
          return updatedProject
        }
        return p
      })
      dispatch(loadProjcetsSilently(updatedProjects))
    }
  }

  useEffect(() => {
    sortPlacements()
  }, [selectedTab])

  useEffect(() => {
    sortPlacements()
  }, [selectedProject])

  const handleCreatePlacement = async (    
    name: string | null, 
    timeZone: string, 
    temperatureUnit: SampleUnit, 
    temperatureLimitParams: TemperatureLimitEntry,
    maturityMethodType: MaturityMethod["type"],
    maturityMethodTemperatureUnit: SampleUnit,
    maturityMethodParams: MaturityParametersEntry,
    curveFitType: CurveFitEntry["type"],
    curveFitParams: CurveFitParametersEntry,
    goalEntries: GoalEntry[]
  ) => {
    if (!accessToken) {
      return
    }

    setIsCreatingNewPlacement(true)

    try {
      let finalName = name
      if (!finalName) {
        finalName = ""
      }

      if (finalName === "") {
        finalName = "My Placement"
      }

      finalName = uniqueString(finalName, selectedProject?.placements.map(p => p.name) ?? [])

      const temperatureLimit = await createTemperatureLimit(accessToken, temperatureLimitParams.minimumTemperature, temperatureLimitParams.maximumTemperature, temperatureLimitParams.difference)
      const curveFit = await createCurveFit(accessToken, curveFitType, curveFitParams)
      const maturityMethod = await createMaturityMethod(accessToken, maturityMethodType, maturityMethodTemperatureUnit, maturityMethodParams, curveFit.id)
      const placement = await createPlacement(accessToken, parseInt(projectId), finalName, timeZone, temperatureUnit, temperatureLimit.id, maturityMethod.id)
      
      for (const goal of goalEntries) {
        if (!goal.name || !goal.value) {
          continue
        }
        
        await createGoal(accessToken, parseInt(projectId), placement.id, goal.name, goal.value)
      }

      const allProjects = await fetchProjects(accessToken)
      await dispatch(loadProjcetsSilently(allProjects))

      logAddedPlacement(parseInt(projectId))

      setNewPlacementDialogOpen(false)
      navigate(`/projects/${projectId}/placements/${placement.id}`) 
    } catch (e) {
      console.log(e)
      setError(true)
    } finally {
      setIsCreatingNewPlacement(false)
    }
  }

  const closePlacementDialog = () => {
    if (isCreatingNewPlacement) { return }
    setNewPlacementDialogOpen(false)
  }

  return (
    <Box sx={{
      height: "100%",
      width: "100%",
    }}>
      <Box sx={{
        height: `calc(100% - ${selectedProject?.userInfo.permission !== "readonly" ? "50px" : "0px"})`,
        overflowY: "auto",
        overflowX: "hidden"
      }}>
        <Typography variant="subtitle2" sx={{
          padding: "0",
          marginTop: "24px",
          marginLeft: "24px",
        }}>
          Placements
        </Typography>
        <Tabs 
          variant="fullWidth" 
          value={selectedTab} 
          onChange={(_event, newValue) => setSelectedTab(newValue)}
          sx={{
            margin: "24px 24px 0px 24px"
          }}
        >
          <Tab label="Name" value={0} />
          <Tab label="Recent" value={1} />
        </Tabs>
        <List sx={{
          width: "100%",
          margin: "12px 0px 0px 0px",
        }}>
          {sortedPlacements.map((placement) => {
            return (
              <React.Fragment key={placement.id}>
                <ListItem alignItems="flex-start" sx={{
                  padding: 0,
                  margin: 0
                }}>
                  <ListItemButton onClick={() => props.onPlacementSelect(placement) } sx={{
                    margin: "0px 0px 0px 0px",
                    padding: "10px 8px",
                    backgroundColor: selectedPlacement?.id === placement.id ? "#000099" : "white",
                    ":hover": {
                      backgroundColor: selectedPlacement?.id === placement.id ? "#000099" : "#F5F5F5",
                      color: selectedPlacement?.id === placement.id ? "white" : "inherit"
                    }
                  }}>
                    <PlacementView 
                      selected={selectedPlacement?.id === placement.id} 
                      placement={placement} 
                      showSettings={selectedProject?.userInfo.permission !== "readonly"}
                      project={selectedProject}
                      onPlacementUpdate={handlePlacementUpdate}
                    />
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            )
          })}
        </List>
      </Box>
      {selectedProject?.userInfo.permission !== "readonly" &&
        <Button
          fullWidth={true}
          variant="contained"
          sx={{
            height: "50px",
            justifyContent: "center",
            alignItems: "center",
            padding: "6px 12px 6px 24px",
            borderRadius: "0px",
          }}
          onClick={() => setNewPlacementDialogOpen(true)}
        >
          <Typography variant="subtitle1" sx={{
            color: "white",
            padding: "0",
            textTransform: "none"
          }}>
          New Placement
          </Typography>
        </Button>
      }
      <Dialog
        open={newPlacementDialogOpen}
        onClose={closePlacementDialog}
        maxWidth="xs"
        fullWidth={true}
        fullScreen={!isDesktop}>
        <NewPlacementView project={selectedProject} onSave={handleCreatePlacement} isLoading={isCreatingNewPlacement} hasError={error} onClose={closePlacementDialog}/>
      </Dialog>
    </Box>
  )
}

export default PlacementListView
