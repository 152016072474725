import { Box, CircularProgress, Dialog } from "@mui/material"
import React, { useEffect, useState } from "react"
import Placement from "../../models/placement"
import Sensor, { excelDataForSensor } from "../../models/sensor"
import { fetchSensorData } from "../../network/network"
import { useAppSelector } from "../../store/hooks"
import SensorListView from "./SensorListView"
import { maximumSampleDate, minimumSampleDate } from "../../utilities/samples"
import TemperatureDifferentialReportPreviewView from "../reports/TemperatureDifferential/TemperatureDifferentialReportPreviewView"
import ChartTabs from "./ChartTabs"
import { useParams } from "react-router-dom"
import xlsx from "xlsx"
import moment from "moment"
import { logExport } from "../../utilities/analytics"

const SensorListContainerView = () => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const { projectId, placementId } = useParams()
  if (!projectId || !placementId) {
    console.log("No project ID in URL.")
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))
  if (selectedProject === undefined) {
    console.log("Project not found.")
    return (<></>)
  }
  const selectedPlacement: Placement | undefined = selectedProject.placements.find(p => p.id === parseInt(placementId))
  if (selectedPlacement === undefined) {
    console.log("Placement not found.")
    return (<></>)
  }
  
  const accessToken = useAppSelector(state => state.user.token)

  const [sensors, setSensors] = useState<Sensor[]>([])
  const [selectedSensorIds, setSelectedSensorIds] = useState<number[]>([])

  const [colorMap, setColorMap] = useState<Map<number, string>>(new Map())

  const [loading, setLoading] = useState(true)

  const selectedSensors = sensors.filter(sensor => selectedSensorIds.includes(sensor.id))
  const startDate = minimumSampleDate(selectedSensors)
  const endDate = maximumSampleDate(selectedSensors)

  useEffect(() => {
    if (!accessToken) {
      return
    }

    const loadSensorData = async () => {
      let needsToLoad = false || loading

      const fetchedSensors = selectedPlacement.sensors.map(async (sensor: Sensor) => {
        const existingSensor = sensors.find(s => s.id === sensor.id)
        if (existingSensor) {
          return {
            ...sensor,
            samples: existingSensor.samples
          }
        } else {
          needsToLoad = true

          return {
            ...sensor,
            samples: await fetchSensorData(accessToken, selectedPlacement.projectId, selectedPlacement.id, sensor.id)
          }
        }
      })

      if (needsToLoad) {
        setLoading(true)
      }

      setSensors(await Promise.all(fetchedSensors))

      if (needsToLoad) {
        setLoading(false)
      }
    }

    loadSensorData()
  }, [selectedPlacement])

  useEffect(() => {
    setSelectedSensorIds([])
  }, [selectedPlacement.id])

  useEffect(() => {
    const colors = [
      "#3876DB",
      "#FF2700",
      "#00C200",
      "#FFEB00",
      "#FF9A00",
      "#8723A1",
      "#66FDFF",
      "#FFC7DE",
      "#BDFA00",
      "#F23BEB",
      "#A16C21",
      "#051582",
      "#9B1300",
      "#E4BCFF",
      "#008081",
      "#FFD8AE",
      "#A6FCC1",
      "#7F8100",
      "#F7F5CD"
    ]

    const sortedBySerial = sensors.sort((a: Sensor, b: Sensor) => {
      if (a.serial < b.serial) {
        return -1
      } else if (a.serial > b.serial) {
        return 1
      } else {
        return 0
      }
    })

    const map: Map<number, string> = new Map()

    sortedBySerial.forEach((sensor, index) => {
      map.set(sensor.id, colors[index % (colors.length - 1)])
    })

    setColorMap(map)
  }, [sensors])

  const [open, setOpen] = useState(false)

  return (
    <>
      <Dialog 
        open={open} 
        onClose={() => {
          setOpen(false)
        }}
        fullScreen
      >
        <TemperatureDifferentialReportPreviewView
          selectedSensors={selectedSensors} 
          placement={selectedPlacement}
          project={selectedProject}
          colorMap={colorMap}
          onClose={() => {
            setOpen(false)
          }}
        />
      </Dialog>
      {loading &&
        <Box sx={{
          marginTop: "12px",
          width: "100%",
        }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
          <CircularProgress />
        </Box>
      }
      {!loading &&
        <Box display="flex" flexDirection="row" sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden"
        }}>
          <Box sx={{
            width: "35%",
            height: "100%",
            overflow: "hidden"
          }}>
            <SensorListView 
              sensors={sensors} 
              onTemperatureDifferentialReportSelected={() => {
                setOpen(!open)
              }}
              onExportToExcelSelected={() => {
                if(selectedPlacement === undefined) {
                  return
                }
                const workbook = xlsx.utils.book_new()
                sensors.forEach(sensor => {
                  const sheet = xlsx.utils.json_to_sheet(excelDataForSensor(sensor, selectedPlacement), { skipHeader: true })
                  xlsx.utils.book_append_sheet(workbook, sheet, sensor.name ?? sensor.serial)
                })
                const filename = selectedPlacement.name + "_" + moment().format("YYYYMMDD[T]HHmmss") + ".xlsx"
                xlsx.writeFile(workbook, filename)
                logExport("excel", selectedPlacement.sensors.length)
              }}
              timezone={selectedPlacement.timeZone}
              placement={selectedPlacement}
              temperatureUnit={selectedPlacement.temperatureUnit}
              selectedSensors={selectedSensorIds}
              reportsDisabled={selectedProject.userInfo.permission === "readonly"}
              colorMap={colorMap}
              onSensorSelectionChange={(changedSensor: Sensor) => {
                const index = selectedSensorIds.indexOf(changedSensor.id)
                if (index === -1) {
                  const newSelectionList = selectedSensorIds.slice()
                  newSelectionList.push(changedSensor.id)
                  setSelectedSensorIds(newSelectionList)
                } else {
                  const newSelectionList = selectedSensorIds.slice()
                  newSelectionList.splice(index, 1)
                  setSelectedSensorIds(newSelectionList)
                }
              }}
            />
          </Box>
          <Box 
            id="sensor-list-container"
            sx={{
              width: "65%",
              height: "100%",
              overflow: "hidden"
            }}
          >
            <ChartTabs 
              project={selectedProject} 
              placement={selectedPlacement} 
              selectedSensors={selectedSensors} 
              colorMap={colorMap}
              startDate={startDate ?? moment()}
              endDate={endDate ?? moment()}
            />
          </Box>
        </Box>
      }
    </>
  )
}

export default SensorListContainerView
