import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
import NavigationBarView from "../reusable/NavigationBarView"
import PlacementListView from "../placements/PlacementListView"
import { Popover, Box } from "@mui/material"
import ProjectSettingsView from "../projects/ProjectSettingsView"
import Placement from "../../models/placement"

const MobilePlacementListScreen = () => {
  const { projectId } = useParams()
  const loadedProjects = useAppSelector(state => state.project.projects)
  if (!projectId) {
    return (<></>)
  }
  const selectedProject = loadedProjects.find(p => p.id === parseInt(projectId))
  if (selectedProject === undefined) {
    return (<></>)
  }

  const navigate = useNavigate()

  const [projectSettings, setProjectSettings] = useState<HTMLElement | null>(null)

  const [title, setTitle] = useState<string>("")

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setProjectSettings(event.currentTarget)
  }

  const popoverIsOpen = Boolean(projectSettings)
  const popoverId = popoverIsOpen ? "placement-specs" : undefined

  const location = useLocation()

  const placementId = (location.pathname.split("/placements/")[1] || undefined)
  let selectedPlacement: Placement | undefined = undefined
  if (placementId) {
    selectedPlacement = selectedProject.placements.find(p => p.id === parseInt(placementId))
  }

  useEffect(() => {
    if (selectedPlacement) {
      setTitle(selectedPlacement.name)
    } else if (selectedProject) {
      setTitle(selectedProject.name)
    }
  }, [location])

  return (
    <>
      <NavigationBarView 
        title={title}
        backButtonTitle=""
        projectSettingsButtonTitle="Settings"
        onBack={() => {
          navigate("/")
        }}
        onProjectSettingsClicked={togglePopover}
      />
      <Box sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        height: "calc(100dvh - 50px)"
      }}>
        <PlacementListView
          onPlacementSelect={(placement) => {
            navigate(`/projects/${projectId}/placements/${placement.id}`)
          }}
        />
        {projectId &&
          <Popover 
            id={popoverId}
            open={popoverIsOpen}
            anchorEl={projectSettings}
            onClose={() => { setProjectSettings(null) }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <ProjectSettingsView project={selectedProject} />
          </Popover>
        }
      </Box>
    </>
  )
}

export default MobilePlacementListScreen
