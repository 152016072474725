import React from "react"
import Sensor from "../../models/sensor"
import SampleUnit from "../../models/units"
import "chartjs-adapter-moment"
import annotationPlugin from "chartjs-plugin-annotation"
import moment from "moment"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js"
import differenceChartData from "./temperatureDifferenceCalculator"
import { convertedDifferenceValue } from "../../utilities/calculations"
import { Line } from "react-chartjs-2"
import Placement from "../../models/placement"
import lcm from "lcm"

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

interface Props {
    sensors: Sensor[]
    temperatureUnit: SampleUnit
    colorMap: Map<number, string>
    start: moment.Moment
    end: moment.Moment
    threshold?: number
    forReport: boolean
    placement: Placement
}

const lcmArray = (arr: number[]) => {
  return arr.reduce((a, b) => lcm(a, b))
}

const TemperatureDifferenceChart = (props: Props) => {
  const differenceMap = differenceChartData(props.sensors, props.start, props.end)
  const graphData: { x: Date, y: number }[] = []
  const sampleInterval = lcmArray(props.sensors.map(sensor => sensor.sampleInterval))
  if (!sampleInterval) {
    return (
      <div>Not enough data to display</div>
    )
  }

  // iterate over dates between start and end increment by sampleInterval
  let highestYValue: number | undefined = undefined
  let lowestYValue: number | undefined = undefined
  let firstSampleTime = null as number | null

  differenceMap.forEach((value, key) => {
    if (value.sensorCount !== props.sensors.length) {
      return
    }

    if (!firstSampleTime) {
      firstSampleTime = key
    } else {
      firstSampleTime = Math.min(firstSampleTime, key)
    }
  })

  if (firstSampleTime !== null) {
    for (let date = firstSampleTime.valueOf(); date <= props.end.valueOf(); date += sampleInterval * 1000 * 60) {
      if (!differenceMap.has(date)) {
        continue
      }
      const value = differenceMap.get(date)
      if (!value || value.sensorCount !== props.sensors.length) {
        graphData.push({
          x: new Date(date),
          y: Number.NaN
        })
      } else {
        const yValue = convertedDifferenceValue(value.max - value.min, props.temperatureUnit)
        if (highestYValue == undefined || yValue > highestYValue) {
          highestYValue = yValue
        }
        if (lowestYValue == undefined || yValue < lowestYValue) {
          lowestYValue = yValue
        }
        graphData.push({
          x: new Date(date),
          y: yValue
        })
      }
    }
  }


  if (props.threshold !== undefined) {
    const thresholdValue = convertedDifferenceValue(props.threshold, props.temperatureUnit)
    if (highestYValue === undefined || thresholdValue > highestYValue) {
      highestYValue = thresholdValue
    }
    if (lowestYValue === undefined || thresholdValue < lowestYValue) {
      lowestYValue = thresholdValue
    }
  }

  let padding = 10
  if (highestYValue !== undefined && lowestYValue !== undefined) {
    const range = highestYValue - lowestYValue
    padding = range * 0.05
  }

  const data = {
    datasets: [{
      borderColor: "#00030",
      backgroundColor: "#000",
      data: graphData,
      borderWidth: 1
    }]
  }

  const options = {
    animation: {
      duration: 0
    },
    responsive: true,
    spansGaps: false,
    maintainAspectRatio: false,
    pointRadius: props.forReport ? 0 : 1,
    plugins: {
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: (context: any) => {
            return moment(context[0].parsed.x).format("MM/DD/YY H:mm")
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: (context: any) => {
            return `${context.parsed.y}º${props.temperatureUnit.toUpperCase()}`
          },
        }
      },
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Difference"
      },
      annotation: {
        annotations: {
          thresholdLine: {}
        }
      }
    },
    scales: {
      x: {
        offset: true,
        type: "time" as const,
        min: props.start?.toLocaleString(),
        max: props.end?.toLocaleString(),
        time: {
          displayFormats: {
            millisecond: "MM/DD/YY H:mm",
            second: "MM/DD/YY H:mm",
            minute: "MM/DD/YY H:mm",
            hour: "MM/DD/YY H:mm",
            day: "MM/DD/YY H:mm",
            week: "MM/DD/YY H:mm",
            month: "MM/DD/YY H:mm",
            quarter: "MM/DD/YY H:mm",
            year: "MM/DD/YY H:mm"
          }
        },
        gridLines: {
          display: true
        },
        ticks: {
          maxTicksLimit: 6,
        }
      },
      y: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        afterFit: (scale: any) => {
          scale.width = 60
        },
        type: "linear" as const,
        suggestedMin: 0,
        suggestedMax: (highestYValue || 0) + padding,
        ticks: {
          callback: function(label: string | number | undefined) {
            return Number(label).toFixed(1) + "º" + props.temperatureUnit.toUpperCase()
          }
        }
      }
    }
  }

  if (props.threshold !== undefined) {
    options.plugins.annotation.annotations["thresholdLine"] = {
      type: "line" as const,
      mode: "horizontal" as const,
      yMin: convertedDifferenceValue(props.threshold, props.temperatureUnit).toFixed(1),
      yMax: convertedDifferenceValue(props.threshold, props.temperatureUnit).toFixed(1),
      borderColor: "black",
      borderWidth: 2,
      borderDash: [6, 6]
    }
  } else {
    // @ts-expect-error - this is a hack to get around the fact that the annotation plugin doesn't support removing annotations
    delete options.plugins.annotation.annotations["thresholdLine"]
  }

  return (
    <Line data={data} options={options} width="100%" height="100%" />
  )
}

export default TemperatureDifferenceChart
