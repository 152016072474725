import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@mui/material"
import React from "react"
import { PlacementEntry } from "../../../models/placement"
import CurveFitView from "./CurveFitView"
import MaturityMethodView from "./MaturityMethodView"
import PlacementGeneralInfoView from "./PlacementGeneralInfoView"
import TemperatureLimitsView from "./TemperatureLimitsView"
import GoalView from "./GoalView"
import MaturityMethod, { isMaturityMethodEntryEqual, MaturityMethodEntry, MaturityParametersEntry } from "../../../models/maturitymethod"
import { CurveFitEntry, CurveFitParametersEntry, isCurveFitEntryEqual } from "../../../models/curvefit"
import { convertCurveFitIntercept, convertedDifferenceValue, convertedTemperatureValue, convertInitialMaturityValue, convertKValue, convertSlope, convertUltimateStrengthValue } from "../../../utilities/calculations"
import { ArrowBack, Close } from "@mui/icons-material"
import { GoalEntry } from "../../../models/goal"
import SampleUnit from "../../../models/units"
import { TemperatureLimitEntry } from "../../../models/temperaturelimit"
import { LoadingButton } from "@mui/lab"
import { calculateMaturityFromStrength, calculateStrengthFromMaturity } from "../../../utilities/maturity"

interface Props {
  entry: PlacementEntry
  onEntryChange: (entry: PlacementEntry) => void
  isLoading: boolean
  onSave: (
    name: string | null, 
    timeZone: string, 
    temperatureUnit: SampleUnit, 
    temperatureLimit: TemperatureLimitEntry,
    maturityMethodType: MaturityMethod["type"],
    maturityMethodTemperatureUnit: SampleUnit,
    maturityMethod: MaturityParametersEntry,
    curveFitType: CurveFitEntry["type"],
    curveFit: CurveFitParametersEntry,
    goalEntries: GoalEntry[]
  ) => void
  hasError?: boolean
  onClose: () => void
}

const PlacementSpecForm = (props: Props) => {
  const [frozenEntry, setFrozenEntry] = React.useState<PlacementEntry>(JSON.parse(JSON.stringify(props.entry)))
  const [showGoalView, setShowGoalView] = React.useState(false)
  const [showMaturityChangedAlert, setShowMaturityChangedAlert] = React.useState(false)
  const [highlightRequiredFields, setHighlightRequiredFields] = React.useState(false)

  const entry = props.entry
  const setEntry = props.onEntryChange

  const handleTemperatureLimitChange = (placementEntry: PlacementEntry) => {
    if (placementEntry.temperatureUnit && entry.temperatureUnit) {
      if (placementEntry.temperatureLimit.minimumTemperature !== null) {
        placementEntry.temperatureLimit.minimumTemperature = convertedTemperatureValue(placementEntry.temperatureLimit.minimumTemperature, placementEntry.temperatureUnit, entry.temperatureUnit)
      }

      if (placementEntry.temperatureLimit.maximumTemperature !== null) {
        placementEntry.temperatureLimit.maximumTemperature = convertedTemperatureValue(placementEntry.temperatureLimit.maximumTemperature, placementEntry.temperatureUnit, entry.temperatureUnit)
      }

      if (placementEntry.temperatureLimit.difference !== null) {
        placementEntry.temperatureLimit.difference = convertedDifferenceValue(placementEntry.temperatureLimit.difference, placementEntry.temperatureUnit, entry.temperatureUnit)
      }
    }

    setEntry(placementEntry)
  }

  const recalculateGoals = (matEntry: MaturityMethodEntry, curveEntry: CurveFitEntry) => {
    if (entry.goals.length !== frozenEntry.goals.length) {
      return []
    }

    const goals = entry.goals

    for (let i = 0; i < goals.length; i++) {
      const frozenValue = frozenEntry.goals[i].value

      if (frozenValue === null) {
        continue
      }

      if (curveEntry.type !== frozenEntry.maturityMethod.curveFit.type) {
        if (curveEntry.type === "disabled") {
          const maturityValue = calculateMaturityFromStrength(matEntry, frozenValue) || null
          goals[i].value = maturityValue
        } else {
          const strengthValue = calculateStrengthFromMaturity(matEntry, frozenValue) || null
          goals[i].value = strengthValue
        }
      } else if (curveEntry.type === "disabled" && matEntry.type === frozenEntry.maturityMethod.type) {
        if (matEntry.type === "nurse saul" && matEntry.temperatureUnit !== frozenEntry.maturityMethod.temperatureUnit) {
          if (matEntry.temperatureUnit === "f") {
            goals[i].value = frozenValue * 9/5
          } else {
            goals[i].value = frozenValue * 5/9
          }
        } else {
          goals[i].value = frozenValue
        }
      } else {
        goals[i].value = frozenValue
      }

      if (curveEntry.type !== "disabled" && curveEntry.parameters.strengthUnit && matEntry.curveFit.parameters.strengthUnit) {
        goals[i].value = convertUltimateStrengthValue(frozenValue, curveEntry.parameters.strengthUnit, matEntry.curveFit.parameters.strengthUnit)
      }
    }

    return goals
  }

  const handleMaturityEntryChange = (matEntry: MaturityMethodEntry) => {
    if (matEntry.temperatureUnit && entry.maturityMethod.temperatureUnit) {
      if (matEntry.parameters.referenceTemperature !== null) {
        matEntry.parameters.referenceTemperature = convertedTemperatureValue(matEntry.parameters.referenceTemperature, matEntry.temperatureUnit, entry.maturityMethod.temperatureUnit)
      }

      if (matEntry.parameters.datumTemperature !== null) {
        matEntry.parameters.datumTemperature = convertedTemperatureValue(matEntry.parameters.datumTemperature, matEntry.temperatureUnit, entry.maturityMethod.temperatureUnit)
      }

      if (matEntry.curveFit.parameters.intercept !== null && matEntry.curveFit.parameters.slope !== null && matEntry.curveFit.parameters.strengthUnit && matEntry.curveFit.type !== "disabled") {
        matEntry.curveFit.parameters.intercept = convertCurveFitIntercept(matEntry.curveFit.parameters.intercept, matEntry.temperatureUnit, matEntry.curveFit.parameters.strengthUnit, matEntry.curveFit.type, matEntry.curveFit.parameters.slope, entry.maturityMethod.temperatureUnit, matEntry.curveFit.parameters.strengthUnit)
      }

      if (matEntry.curveFit.parameters.kValue !== null && matEntry.type !== "arrhenius") {
        matEntry.curveFit.parameters.kValue = convertKValue(matEntry.curveFit.parameters.kValue, matEntry.temperatureUnit, entry.maturityMethod.temperatureUnit)
      }

      if (matEntry.curveFit.parameters.initialMaturity !== null) {
        matEntry.curveFit.parameters.initialMaturity = convertInitialMaturityValue(matEntry.curveFit.parameters.initialMaturity, matEntry.temperatureUnit, entry.maturityMethod.temperatureUnit)
      }
    }

    setEntry({
      ...entry,
      maturityMethod: matEntry,
      goals: recalculateGoals(matEntry, matEntry.curveFit)
    })
  }



  const handleCurveFitEntryChange = (curveEntry: CurveFitEntry) => {
    if (curveEntry.parameters.strengthUnit && entry.maturityMethod.curveFit.parameters.strengthUnit) {
      if (curveEntry.parameters.slope !== null) {
        curveEntry.parameters.slope = convertSlope(curveEntry.parameters.slope, curveEntry.parameters.strengthUnit, entry.maturityMethod.curveFit.parameters.strengthUnit)
      }

      if (curveEntry.parameters.intercept !== null && curveEntry.parameters.slope !== null && entry.maturityMethod.temperatureUnit) {
        curveEntry.parameters.intercept = convertCurveFitIntercept(curveEntry.parameters.intercept, entry.maturityMethod.temperatureUnit, curveEntry.parameters.strengthUnit, curveEntry.type, curveEntry.parameters.slope, entry.maturityMethod.temperatureUnit, entry.maturityMethod.curveFit.parameters.strengthUnit)
      }

      if (curveEntry.parameters.ultimateStrength !== null) {
        curveEntry.parameters.ultimateStrength = convertUltimateStrengthValue(curveEntry.parameters.ultimateStrength, curveEntry.parameters.strengthUnit, entry.maturityMethod.curveFit.parameters.strengthUnit)
      }
    }

    setEntry({
      ...entry,
      maturityMethod: {
        ...entry.maturityMethod,
        curveFit: curveEntry
      },
      goals: recalculateGoals(entry.maturityMethod, curveEntry)
    })
  }

  const handleGoalsChange = (goals: GoalEntry[]) => {
    setEntry({
      ...entry,
      goals: goals
    })
  }
  
  const handleGoalAdd = () => {
    const goals = entry.goals
    goals.push({
      id: null,
      name: null,
      value: null
    })

    setEntry({
      ...entry,
      goals: goals
    })
  }

  const getFinalEntry = () => {
    try {
      if (!entry.temperatureUnit) {
        throw new Error("Temperature unit is not set")
      }

      const temperatureLimitParams = celsiusBasedTemperatureLimitParams(entry)
      const maturityMethodParams = celsiusBasedMaturityMethodParams(entry)
      const curveFitParams = mpaCelsiusBasedCurveFitParams(entry)

      const goalEntries = entry.goals.map(goal => {
        if (goal.value === null) {
          return null
        }

        if (!goal.name) {
          throw new Error("Goal name is not set")
        }

        if (entry.maturityMethod.curveFit.type === "disabled") {
          if (entry.maturityMethod.type === "nurse saul" && entry.maturityMethod.temperatureUnit === "f") {
            return {
              id: goal.id,
              name: goal.name,
              value: goal.value * 5/9
            }
          } else {
            return {
              id: goal.id,
              name: goal.name,
              value: goal.value
            }
          }
        } else {
          let maturityValue = calculateMaturityFromStrength(entry.maturityMethod, goal.value)

          if (maturityValue === null || maturityValue === undefined) {
            throw new Error("Maturity value is not set")
          }
          
          if (entry.maturityMethod.type == "nurse saul" && entry.maturityMethod.temperatureUnit === "f") {
            maturityValue = maturityValue * 5/9
          }

          return {
            id: goal.id,
            name: goal.name,
            value: maturityValue
          }
        }
      }).filter(goal => goal !== null)

      return {
        name: entry.name,
        timeZone: entry.timeZone,
        temperatureUnit: entry.temperatureUnit,
        temperatureLimit: temperatureLimitParams,
        maturityMethodType: entry.maturityMethod.type,
        maturityMethodTemperatureUnit: entry.maturityMethod.temperatureUnit === "" ? "c" : entry.maturityMethod.temperatureUnit,
        maturityMethod: maturityMethodParams,
        curveFitType: entry.maturityMethod.curveFit.type,
        curveFit: {
          ...curveFitParams,
          strengthUnit: entry.maturityMethod.curveFit.parameters.strengthUnit === "" ? "mpa" : entry.maturityMethod.curveFit.parameters.strengthUnit
        },
        goalEntries: goalEntries
      }
    } catch (e) {
      setHighlightRequiredFields(true)
      return null
    }
  }

  const handleSave = () => {
    setHighlightRequiredFields(false)
    setShowMaturityChangedAlert(false)

    const finalEntry = getFinalEntry()

    if (!finalEntry) {
      return
    } else {
      props.onSave(
        finalEntry.name,
        finalEntry.timeZone,
        finalEntry.temperatureUnit,
        finalEntry.temperatureLimit,
        finalEntry.maturityMethodType,
        finalEntry.maturityMethodTemperatureUnit,
        finalEntry.maturityMethod,
        finalEntry.curveFitType,
        finalEntry.curveFit,
        finalEntry.goalEntries
      )
    }
  }

  const handleSaveEarly = () => {
    const finalEntry = getFinalEntry()

    if (!finalEntry) {
      return
    }

    const maturityMethodRequiresUpdate = !isMaturityMethodEntryEqual(entry.maturityMethod, frozenEntry.maturityMethod)
    const curveFitRequiresUpdate = !isCurveFitEntryEqual(entry.maturityMethod.curveFit, frozenEntry.maturityMethod.curveFit)

    if ((maturityMethodRequiresUpdate || curveFitRequiresUpdate) && entry.goals.length > 0) {
      setShowMaturityChangedAlert(true)
      return
    }

    setHighlightRequiredFields(false)
    setShowMaturityChangedAlert(false)

    props.onSave(
      finalEntry.name,
      finalEntry.timeZone,
      finalEntry.temperatureUnit,
      finalEntry.temperatureLimit,
      finalEntry.maturityMethodType,
      finalEntry.maturityMethodTemperatureUnit,
      finalEntry.maturityMethod,
      finalEntry.curveFitType,
      finalEntry.curveFit,
      finalEntry.goalEntries
    )
  }

  const showGoals = () => {
    try {
      celsiusBasedMaturityMethodParams(entry)
      mpaCelsiusBasedCurveFitParams(entry)
      setFrozenEntry(JSON.parse(JSON.stringify(props.entry)))
      setShowGoalView(true)
    } catch (e) {
      console.log(e)
      setHighlightRequiredFields(true)
    }
  }

  return (
    <>
      <Box sx={{
        padding: "24px"
      }}>
        {!showGoalView &&
        <>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h5" sx={{
              width: "100%"
            }}>
              Specifications
            </Typography>
            <IconButton sx={{
              margin: "-8px"
            }} onClick={props.onClose}>
              <Close />
            </IconButton>
          </Box>
          <PlacementGeneralInfoView entry={entry} onEntryChange={setEntry} disabled={props.isLoading} highlightRequiredFields={highlightRequiredFields}/>
          <TemperatureLimitsView entry={entry} onEntryChange={handleTemperatureLimitChange} disabled={props.isLoading} highlightRequiredFields={highlightRequiredFields} />
          <MaturityMethodView entry={entry.maturityMethod} onEntryChange={handleMaturityEntryChange} disabled={props.isLoading} highlightRequiredFields={highlightRequiredFields} />
          <CurveFitView maturityMethod={entry.maturityMethod} entry={entry.maturityMethod.curveFit} onEntryChange={handleCurveFitEntryChange} disabled={props.isLoading} highlightRequiredFields={highlightRequiredFields} />
        </>
        }
        {!showGoalView && props.hasError &&
          <Typography sx={{
            color: "red"
          }}>
            An error occurred while saving. Please try again.
          </Typography>
        }
        {entry.maturityMethod.type === "disabled" && !showGoalView &&
          <LoadingButton
            loading={props.isLoading}
            variant="contained"
            color="primary"
            sx={{
              marginTop: "12px"
            }}
            onClick={handleSave}
            fullWidth
          >
            Save
          </LoadingButton>
        }
        {entry.maturityMethod.type !== "disabled" && !showGoalView &&
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginTop: "12px"
              }}
              onClick={showGoals}
              fullWidth
              disabled={props.isLoading}
            >
              Enter Goals
            </Button>
            <LoadingButton
              loading={props.isLoading}
              variant="outlined"
              color="primary"
              sx={{
                marginTop: "12px"
              }}
              onClick={handleSaveEarly}
              fullWidth
            >
              Save & Finish
            </LoadingButton>
          </>
        }
        {showGoalView &&
          <>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton sx={{
                margin: "-8px",
                marginRight: "8px"
              }} onClick={() => { setShowGoalView(false) }}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h5" sx={{
                width: "100%"
              }}>
                Goals
              </Typography>
              {entry.goals.length < 3 &&
                <Button
                  variant="text"
                  color="primary"
                  sx={{ 
                    textTransform: "none",
                    fontWeight: "normal",
                    background: "none",
                    padding: "0px",
                    whiteSpace: "nowrap",
                    minWidth: "auto",
                    ":hover": { background: "none" }
                  }}
                  onClick={handleGoalAdd}
                >
                  + Add Goal
                </Button>
              }
            </Box>
            <GoalView entry={entry} onGoalsChange={handleGoalsChange} disabled={props.isLoading} highlightRequiredFields={highlightRequiredFields} />
            {props.hasError &&
              <Typography sx={{
                color: "red"
              }}>
                An error occurred while saving. Please try again.
              </Typography>
            }
            <LoadingButton
              loading={props.isLoading}
              variant="contained"
              color="primary"
              sx={{
                marginTop: "12px"
              }}
              onClick={handleSave}
              fullWidth
            >
              Save
            </LoadingButton>
          </>
        }
      </Box>
      <Dialog
        open={showMaturityChangedAlert}
        onClose={() => setShowMaturityChangedAlert(false)}
      >
        <DialogTitle>
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changes were made to maturity parameters. Please verify goals are as expected.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleSave}>Save and Exit</Button>
          <Button variant="contained" onClick={() => {
            setShowMaturityChangedAlert(false)
            setShowGoalView(true)
          }} autoFocus>
            View Goals
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const celsiusBasedTemperatureLimitParams = (entry: PlacementEntry) => {
  if (!entry.temperatureUnit) {
    throw new Error("Temperature unit is not set")
  }

  return {
    minimumTemperature: entry.temperatureLimit.minimumTemperature && convertedTemperatureValue(entry.temperatureLimit.minimumTemperature, "c", entry.temperatureUnit),
    maximumTemperature: entry.temperatureLimit.maximumTemperature && convertedTemperatureValue(entry.temperatureLimit.maximumTemperature, "c", entry.temperatureUnit),
    difference: entry.temperatureLimit.difference && convertedDifferenceValue(entry.temperatureLimit.difference, "c", entry.temperatureUnit)
  }
}

export const celsiusBasedMaturityMethodParams = (entry: PlacementEntry) => {
  if (entry.maturityMethod.type === "disabled") {
    return {
      activationEnergy: null,
      referenceTemperature: null,
      datumTemperature: null
    }
  }

  if (entry.maturityMethod.temperatureUnit === "") {
    throw new Error("Temperature unit is not set")
  }

  if (entry.maturityMethod.type === "arrhenius") {
    if (entry.maturityMethod.parameters.activationEnergy === null) {
      throw new Error("Activation energy is not set")
    }

    if (entry.maturityMethod.parameters.referenceTemperature === null) {
      throw new Error("Reference temperature is not set")
    }
  }

  if (entry.maturityMethod.type === "nurse saul") {
    if (entry.maturityMethod.parameters.datumTemperature === null) {
      throw new Error("Datum temperature is not set")
    }
  }

  return {
    activationEnergy: entry.maturityMethod.parameters.activationEnergy,
    referenceTemperature: entry.maturityMethod.parameters.referenceTemperature && convertedTemperatureValue(entry.maturityMethod.parameters.referenceTemperature, "c", entry.maturityMethod.temperatureUnit),
    datumTemperature: entry.maturityMethod.parameters.datumTemperature && convertedTemperatureValue(entry.maturityMethod.parameters.datumTemperature, "c", entry.maturityMethod.temperatureUnit)
  }
}

export const mpaCelsiusBasedCurveFitParams = (entry: PlacementEntry) => {
  if (entry.maturityMethod.curveFit.type === "disabled") {
    return {
      intercept: null,
      kValue: null,
      slope: null,
      ultimateStrength: null,
      initialMaturity: null,
      strengthUnit: null,
      initialEquivalentAge: null
    }
  }

  if (entry.maturityMethod.curveFit.parameters.strengthUnit === "") {
    throw new Error("Strength unit is not set")
  }

  if (!entry.maturityMethod.temperatureUnit) {
    throw new Error("Temperature unit is not set")
  }

  if (entry.maturityMethod.curveFit.type === "log" || entry.maturityMethod.curveFit.type === "ln") {
    if (entry.maturityMethod.curveFit.parameters.slope === null) {
      throw new Error("Slope is not set")
    }

    if (entry.maturityMethod.curveFit.parameters.intercept === null) {
      throw new Error("Intercept is not set")
    }

    return {
      intercept: convertCurveFitIntercept(entry.maturityMethod.curveFit.parameters.intercept, "c", "mpa", entry.maturityMethod.curveFit.type, entry.maturityMethod.curveFit.parameters.slope, entry.maturityMethod.temperatureUnit, entry.maturityMethod.curveFit.parameters.strengthUnit),
      kValue: null,
      slope: convertSlope(entry.maturityMethod.curveFit.parameters.slope, "mpa", entry.maturityMethod.curveFit.parameters.strengthUnit),
      ultimateStrength: null,
      initialMaturity: null,
      strengthUnit: entry.maturityMethod.curveFit.parameters.strengthUnit,
      initialEquivalentAge: null
    }
  } else {
    if (entry.maturityMethod.curveFit.parameters.kValue === null) {
      throw new Error("K value is not set")
    }

    if (entry.maturityMethod.curveFit.parameters.ultimateStrength === null) {
      throw new Error("Ultimate strength is not set")
    }

    if (entry.maturityMethod.type === "arrhenius") {
      if (entry.maturityMethod.curveFit.parameters.initialEquivalentAge === null) {
        throw new Error("Initial equivalent age is not set")
      }

      return {
        intercept: null,
        kValue: entry.maturityMethod.curveFit.parameters.kValue,
        slope: null,
        ultimateStrength: convertUltimateStrengthValue(entry.maturityMethod.curveFit.parameters.ultimateStrength, "mpa", entry.maturityMethod.curveFit.parameters.strengthUnit),
        initialMaturity: null,
        strengthUnit: entry.maturityMethod.curveFit.parameters.strengthUnit,
        initialEquivalentAge: entry.maturityMethod.curveFit.parameters.initialEquivalentAge
      }
    } else {
      if (entry.maturityMethod.curveFit.parameters.initialMaturity === null) {
        throw new Error("Initial maturity is not set")
      }

      return {
        intercept: null,
        kValue: convertKValue(entry.maturityMethod.curveFit.parameters.kValue, "c", entry.maturityMethod.temperatureUnit),
        slope: null,
        ultimateStrength: convertUltimateStrengthValue(entry.maturityMethod.curveFit.parameters.ultimateStrength, "mpa", entry.maturityMethod.curveFit.parameters.strengthUnit),
        initialMaturity: convertInitialMaturityValue(entry.maturityMethod.curveFit.parameters.initialMaturity, "c", entry.maturityMethod.temperatureUnit),
        strengthUnit: entry.maturityMethod.curveFit.parameters.strengthUnit,
        initialEquivalentAge: null
      }
    }
  }
}

export default PlacementSpecForm